<template>
  <b-card>
    <ejs-toast
      id="toast_type"
      ref="toastRef"
      :position="{ X: 'Right' }"
    />
    <!-- {{ this.$store.state.products.productGeneral }} -->
    <!-- media -->
    <b-row>
      <!-- <b-col
        lg="8"
        class="mb-2"
      >
        <b-media no-body>
          <b-media-aside>
            <b-link>
              <b-img
                ref="previewEl"
                :src="img"
                rounded
                height="80"
              />
            </b-link>
          </b-media-aside>

          <b-media-body class="mt-75 ml-75">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              size="sm"
              class="mb-75 mr-75"
              @click="$refs.refInputEl.$el.click()"
            >
              Upload
            </b-button>
            <b-form-file
              ref="refInputEl"
              v-model="profileFile"
              accept=".jpg, .png, .gif"
              :hidden="true"
              plain
              @input="inputImageRenderer"
            />
            <b-card-text>Allowed JPG, GIF or PNG. Max size of 800kB</b-card-text>
          </b-media-body>
        </b-media>
      </b-col> -->
    </b-row>
    <!-- {{ dizionari }} -->
    <!--/ media -->
    <!-- form -->
    <validation-observer ref="form-product-general-observer">
      <b-form
        @submit.prevent="onSubmit"
      >
        <b-row>
          <b-col
            cols="12 d-flex"
          >
            <b-form-group
              label="Attivo"
              label-for="product-attivo"
              label-class="font-weight-bolder"
              class="mr-4"
            >
              <b-form-checkbox
                v-model="product.attivo"
                name="check-button"
                :value="1"
                :unchecked-value="0"
                switch
                inline
                class="mb-1"
              >
                <span class="switch-icon-left">
                  <feather-icon icon="CheckIcon" />
                </span>
                <span class="switch-icon-right">
                  <feather-icon icon="XIcon" />
                </span>
              </b-form-checkbox>
            </b-form-group>
            <b-form-group
              label="Disponibile"
              label-for="product-disponibile"
              label-class="font-weight-bolder"
              class="mr-4"
            >
              <b-form-checkbox
                v-model="product.disponibile"
                name="check-button"
                :value="1"
                :unchecked-value="0"
                switch
                inline
                class="mb-1"
              >
                <span class="switch-icon-left">
                  <feather-icon icon="CheckIcon" />
                </span>
                <span class="switch-icon-right">
                  <feather-icon icon="XIcon" />
                </span>
              </b-form-checkbox>
            </b-form-group>
            <b-form-group
              label="Visibile"
              label-for="product-Visibile"
              label-class="font-weight-bolder"
              class="mr-4"
            >
              <b-form-checkbox
                v-model="product.visibile"
                name="check-button"
                :value="1"
                :unchecked-value="0"
                switch
                inline
                class="mb-1"
              >
                <span class="switch-icon-left">
                  <feather-icon icon="CheckIcon" />
                </span>
                <span class="switch-icon-right">
                  <feather-icon icon="XIcon" />
                </span>
              </b-form-checkbox>
            </b-form-group>
            <b-form-group
              label="In Evidenza"
              label-for="product-Evidenza"
              label-class="font-weight-bolder"
              class="mr-4"
            >
              <b-form-checkbox
                v-model="product.inEvidenza"
                name="check-button"
                :value="1"
                :unchecked-value="0"
                switch
                inline
                class="mb-1"
              >
                <span class="switch-icon-left">
                  <feather-icon icon="CheckIcon" />
                </span>
                <span class="switch-icon-right">
                  <feather-icon icon="XIcon" />
                </span>
              </b-form-checkbox>
            </b-form-group>
          </b-col>
          <b-col
            cols="2"
          >
            <b-form-group
              label="Codice"
              label-for="product-codProduct"
              label-class="font-weight-bolder"
            >
              <validation-provider
                #default="{ errors }"
                name="Cod. Prod."
                rules="required"
              >
                <b-form-input
                  id="product-cod"
                  v-model="product.codice"
                  placeholder="Codice"
                  name="codProduct"
                />
                <small class="text-danger mt-25">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            sm="5"
          >
            <b-form-group
              label="Nome"
              label-for="product-name"
              label-class="font-weight-bolder"
            >
              <validation-provider
                #default="{ errors }"
                name="Nome"
                rules="required"
              >
                <b-form-input
                  id="product-name"
                  v-model="product.nome"
                  placeholder="Nome prodotto"
                  name="product-name"
                />
                <small class="text-danger mt-25">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            sm="5"
          >
            <b-form-group
              label="Tipo"
              label-for="product-type"
              label-class="font-weight-bolder"
            >
              <validation-provider
                #default="{ errors }"
                name="tipo"
                rules="required"
              >
                <v-select
                  v-model="product.tipoProdotto"
                  :clarable="false"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="dizionari.tipoProdottoOption"
                  label="descrizione"
                  :reduce="val => ({ id: val.id})"
                  placeholder="Tipo"
                />
                <small class="text-danger mt-25">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="6"
            lg="2"
            class=""
          >
            <b-form-group
              label="Unità"
              label-for="product-unit"
              label-class="font-weight-bolder"
            >
              <validation-provider
                #default="{ errors }"
                name="unità"
                rules="required"
              >
                <v-select
                  v-model="product.unitaMisura"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="descrizione"
                  :clearable="false"
                  :options="dizionari.unitaMisura"
                  :reduce="val => ({ id: val.id})"
                  placeholder="Unità"
                />
                <small class="text-danger mt-25">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- v-if="$store.state.products.productGeneral.general.type === 'Servizio'" -->
          <b-col
            cols="3"
            lg="2"
            class=""
          >
            <b-form-group
              label="Unità Utilizzata"
              label-for="product-unit"
              label-class="font-weight-bolder"
            >
              <validation-provider
                #default="{ errors }"
                name="unità"
                rules="required"
              >
                <v-select
                  v-model="product.unitaUtilizzo"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="descrizione"
                  :clearable="false"
                  :options="dizionari.unitaUtilizzo"
                  :reduce="val => ({ id: val.id})"
                  placeholder="Unità utilizzata"
                />
                <small class="text-danger mt-25">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            :md="'5'"
          >
            <b-form-group
              label="Set Attributi"
              label-for="set-attributes"
              label-class="font-weight-bolder"
            >
              <!-- <label
                for="set-attributes"
                label-class="font-weight-bolder"
              >Set Attributi</label> -->
              <validation-provider
                #default="{ errors }"
                name="set attributi"
                rules="required"
              >
                <div class="d-flex">
                  <v-select
                    id="set-attributes"
                    v-model="product.setAttributi"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="descrizione"
                    :clearable="false"
                    :options="dizionari.setAttributoOption"
                    style="width: 100%"
                    class="mr-50"
                    :reduce="val => ({ id: val.id})"
                    placeholder="Unità utilizzata"
                  />
                  <!-- <b-dropdown
                    variant="outline-primary"
                    dropleft
                  >
                    <template #button-content>
                      <feather-icon icon="Edit2Icon" />
                    </template>
                    <b-dropdown-item>
                      <b-link :to="{name:'set-attributes'}">
                        <small>Gestisci Set Attributi</small>
                      </b-link>
                    </b-dropdown-item>
                    <b-dropdown-divider />
                    <b-dropdown-item>Separated link</b-dropdown-item>
                  </b-dropdown> -->
                </div>
                <small class="text-danger mt-25">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            md="3"
          >
            <b-form-group
              label="Categorie"
              label-for="set-attributes"
              label-class="font-weight-bolder"
            >
              <validation-provider
                #default="{ errors }"
                name="categorie"
                rules="required"
              >
                <div class="d-flex">
                  <v-select
                    id=""
                    v-model="product.categorie"
                    :options="dizionari.categorieOption"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    multiple
                    placeholder="categorie"
                    label="descrizione"
                    class="mr-50"
                    style="width:100%"
                    :reduce="val => ({ id: val.id })"
                  />
                  <!-- <b-dropdown
                    variant="outline-primary"
                    dropleft
                  >
                    <template #button-content>
                      <feather-icon icon="Edit2Icon" />
                    </template>
                    <b-dropdown-item>
                      <b-link :to="{name:'manage-category'}">
                        <small>Gestisci Categorie</small>
                      </b-link>
                    </b-dropdown-item>
                    <b-dropdown-divider />
                    <b-dropdown-item>Separated link</b-dropdown-item>
                  </b-dropdown> -->
                </div>
                <small class="text-danger mt-25">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="6"
            md="4"
            lg="3"
          >
            <b-form-group
              label="Iva Vendita"
              label-for="product-iva"
              label-class="font-weight-bolder"
            >
              <validation-provider
                #default="{ errors }"
                name="iva"
                rules="required"
              >
                <v-select
                  v-model="product.aliquotaIva"
                  :options="dizionari.aliquotaIvaOption"
                  :clearable="false"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  placeholder="Iva"
                  label="descrizione"
                  :reduce="val => ({ id: val.id })"
                /><!-- :options="dizionari.aliquotaIvaOption" -->
                <small class="text-danger mt-25">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            cols="6"
            md="4"
            lg="3"
          >
            <b-form-group
              label="Costo Acquisto"
              label-for="product-price1"
              label-class="font-weight-bolder"
            >
              <validation-provider
                #default="{ errors }"
                name="costo"
                rules="required"
              >
                <b-input-group append="€">
                  <b-form-input
                    id="product-price1"
                    v-model="product.costo"
                    placeholder="Prezzo..."
                  />
                </b-input-group>
                <small class="text-danger mt-25">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            cols="6"
            md="4"
            lg="3"
          >
            <b-form-group
              label="Prezzo Vendita"
              label-for="product-price2"
              label-class="font-weight-bolder"
            >
              <validation-provider
                #default="{ errors }"
                name="prezzo"
                rules="required"
              >
                <b-input-group append="€">
                  <b-form-input
                    id="product-price2"
                    v-model="product.prezzoVendita"
                    placeholder="Prezzo..."
                  />
                </b-input-group>
                <small class="text-danger mt-25">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <!-- v-if="$store.state.products.productGeneral.general.type === 'Servizio'" -->
          <b-col
            lg="4"
            class=""
          >
            <b-form-group
              label="Stato Servizio"
              label-for="product-state"
              label-class="font-weight-bolder"
            >
              <validation-provider
                #default="{ errors }"
                name="stato"
                rules="required"
              >
                <v-select
                  v-model="product.statoServizio"
                  :clarable="false"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="stateService"
                />
                <small class="text-danger mt-25">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            md="12"
          >
            <b-form-group
              label="Descrizione Breve"
              label-for="product-description"
              label-class="font-weight-bolder"
            >
              <b-form-textarea
                id="product-description"
                v-model="product.descrizioneBreve"
                placeholder="Descrizione..."
                rows="3"
              />
            </b-form-group>
          </b-col>
          <b-col
            md="12"
          >
            <b-form-group
              label="Note"
              label-for="product-note"
              label-class="font-weight-bolder"
            >
              <b-form-textarea
                id="product-note"
                v-model="product.note"
                placeholder="Descrizione..."
                rows="2"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            class="d-flex flex-row justify-content-end"
          >
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              class="mt-2"
              @click.prevent="onReset"
            >
              Reset
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-2 mr-1"
              type="submit"
            >
              Aggiungi
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
// import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import { required } from '@validations'
import Vue from 'vue'
import {
  VBToggle, BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BFormTextarea, BFormCheckbox, BInputGroup, // BDropdown, BDropdownItem, BDropdownDivider,  BLink
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ToastPlugin } from '@syncfusion/ej2-vue-notifications'
import { alertProduct } from '@core/mixins/ui/alert'
import Ripple from 'vue-ripple-directive'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'
import axios from 'axios'

Vue.use(ToastPlugin)

export default {
  components: {
    // BFormFile,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    // BCardText,
    // BMedia,
    // BMediaAside,
    // BMediaBody,
    // BLink,
    // BImg,
    BFormTextarea,
    vSelect,
    BFormCheckbox,
    BInputGroup,
    /* BDropdown,
    BDropdownItem,
    BDropdownDivider, */
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple,
  },
  mixins: [alertProduct],
  props: {
    dizionari: {
      type: Object,
      required: true,
      default: (() => {}),
    },
  },
  data() {
    return {
      required,
      product: {
        id: 0,
        attivo: 0,
        disponibile: 0,
        visibile: 0,
        inEvidenza: 0,
        codice: '',
        nome: '',
        tipoProdotto: '',
        unitaMisura: '',
        unitaUtilizzo: { id: 11, descrizione: 'Una Tantum' },
        setAttributi: { id: 1, descrizione: 'Default' },
        categorie: [{ id: 1, descrizione: 'Default' }],
        statoServizio: '',
        note: '',
        aliquotaIva: '',
        prezzoVendita: '',
        costo: '',
        // prezzoSuggerito: 10,
        descrizioneBreve: '',
      },
      productToReset: {},
      profileFile: null,
      stateService: ['Sviluppo', 'Operativo', 'Ritirato'],
      locale: 'it',
    }
  },
  mounted() {
    this.productToReset = { ...this.product }
    localize(this.locale)
  },
  methods: {
    onReset() {
      // console.log('resetForm')
      this.product = { ...this.productToReset }
    },
    async onSubmit() {
      // console.log('ciao', [{ ...this.product }])
      // console.log('prodotto da aggiungere', this.product)
      await axios.post(process.env.VUE_APP_URL_API_SERVER, JSON.stringify([{ ...this.product }]),
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
            organizzazione: JSON.parse(sessionStorage.getItem('activeAgency')).value,
            classe: 'Cat-Prodotto',
            metodo: 'setProdottoGenerale',
            ACL: 'invoice-list',
          },
        }).then(res => {
        // console.log('edited', res)
        if (res.data.esito === 'OK') {
          // this.productAddedCorrectlyAlert()
          this.productToReset = { ...this.product }
          this.$refs.toastRef.show({
            title: 'Operazione Completata!', content: 'Prodotto/i Inserito/i Correttamente', cssClass: 'e-toast-success', icon: 'e-success e-circle-check',
          })
          setTimeout(() => {
            this.$router.push({ name: 'details-products', params: { id: res.data.prodotto[0].id } })
          }, 3000)
        } else if (res.data.esito === 'KO') {
          // this.productAddedCheckDataAlert()
          this.$refs.toastRef.show({
            title: 'Errore Inserimento', content: res.data.messaggio, cssClass: 'e-toast-danger', icon: 'e-error toast-icons',
          })
        }
      }).catch(() => {
        // this.productAddedCheckDataAlert()
        this.$refs.toastRef.show({
          title: 'Errore Inserimento', content: 'Controlla i dati inseriti', cssClass: 'e-toast-danger', icon: 'e-error toast-icons',
        })
      })
      // console.log(this.product)
      // this.$router.push({ name: 'details-products', params: { id: this.product.codice } })
    },
  },
  setup() {
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, previewEl)

    return {
      refInputEl,
      previewEl,
      inputImageRenderer,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
