<template>
  <b-tabs
    vertical
    pills
    content-class="col-12 col-md-10 mt-1 mt-md-0"
    nav-wrapper-class="col-md-2 col-12"
    nav-class="nav-left"
  >

    <!-- general tab -->
    <b-tab active>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="UserIcon"
          size="18"
        />
        <span class="font-weight-bold">Generali</span>
      </template>

      <general-add :dizionari="dizionari" />
    </b-tab>
    <div v-if="windowWidth >= 768">
      <b-tab
        disabled
        class="d-none d-lg-block"
      >

        <!-- title -->
        <template #title>
          <feather-icon
            icon="LockIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold">Codici</span>
        </template>

      </b-tab>
      <!-- info -->
      <b-tab
        disabled
        style="display:none"
      >
        <!-- title -->
        <template #title>
          <feather-icon
            icon="InfoIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold">Caratteristiche</span>
        </template>

      </b-tab>
      <!-- <b-tab
        disabled
        class="d-none d-lg-block"
      >
        <template #title>
          <feather-icon
            icon="UserIcon"
            size="18"
          />
          <span class="font-weight-bold">SetAttributi</span>
        </template>
      </b-tab> -->
      <!--/ general tab -->
      <!-- <b-tab
        disabled
        class="d-none d-lg-block"
      >
        <template #title>
          <feather-icon
            icon="InfoIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold">Foto e Video</span>
        </template>

      </b-tab> -->
      <b-tab
        disabled
        class="d-none d-lg-block"
      >
        <!-- title -->
        <template #title>
          <feather-icon
            icon="InfoIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold">Magazzino</span>
        </template>

      </b-tab>
      <b-tab
        disabled
        class="d-none d-lg-block"
      >
        <!-- title -->
        <template #title>
          <feather-icon
            icon="InfoIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold">Prezzi e Listini</span>
        </template>

      </b-tab>
    </div>
  </b-tabs>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import axios from 'axios'
import GeneralAdd from './tabs/GeneralAdd.vue'

export default {
  components: {
    BTabs,
    BTab,
    GeneralAdd,
  },
  data() {
    return {
      options: {},
      dizionari: {
        aliquotaIvaOption: [],
        categorieOption: [],
        setAttributoOption: [],
        tipoProdottoOption: [],
        unitaMisura: [],
        unitaUtilizzo: [],
      },
    }
  },
  computed: {
    windowWidth() {
      return window.innerWidth
    },
  },
  mounted() {
    this.getCombo()
  },
  methods: {
    async getCombo() {
      await axios.post(process.env.VUE_APP_URL_API_SERVER, {},
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
            organizzazione: JSON.parse(sessionStorage.getItem('activeAgency')).value,
            classe: 'Diz-Dizionari',
            metodo: 'getDizionariCombo',
            ACL: '',
          },
        }).then(res => {
        // console.log('getDizionariCombo', res)
        if (res.data.esito === 'OK') {
          // console.log('getDizionariCombo', res)
          const {
            aliquotaIva, categoria, setAttributo, tipoProdotto, unitaMisura, unitaUtilizzo,
          } = res.data
          this.dizionari.aliquotaIvaOption = aliquotaIva
          this.dizionari.categorieOption = categoria
          this.dizionari.setAttributoOption = setAttributo
          this.dizionari.tipoProdottoOption = tipoProdotto
          this.dizionari.unitaMisura = unitaMisura
          this.dizionari.unitaUtilizzo = unitaUtilizzo
        }
      })
    },
  },
}
</script>
